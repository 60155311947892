import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import {
  Container,
  Header,
  AddButton,
  BackButton,
  TeamTable,

  SaveButton,
  CancelButton,
  EditButton,
  DeleteButton,
} from "./ManageBasketball.styled";
import { useSeasons } from "./hooks/useSeasons";

function ManageSeasons() {
  const {
    seasons,
    loading,
    error,
    addSeason,
    updateSeason,
    deleteSeason,
    toggleSeasonActive,
    togglePlayoffs,
  } = useSeasons();

  const [editingId, setEditingId] = useState(null);
  const [newSeasonName, setNewSeasonName] = useState("");
  const [addingNew, setAddingNew] = useState(false);
  const navigate = useNavigate();

  const handleSaveNewSeason = async () => {
    if (!newSeasonName.trim()) {
      alert("Season name cannot be empty.");
      return;
    }
    await addSeason(newSeasonName);
    setAddingNew(false);
    setNewSeasonName("");
  };

  const handleSaveEdit = async () => {
    if (!newSeasonName.trim()) {
      alert("Season name cannot be empty.");
      return;
    }
    await updateSeason(editingId, newSeasonName);
    setEditingId(null);
    setNewSeasonName("");
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container>
      <Header>
        <h1>Basketball Seasons</h1>
      </Header>
      <div style={{ marginBottom: "20px" }}>
        <BackButton onClick={() => navigate(-1)}>Back</BackButton>
      </div>
      
      <TeamTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Season Name</th>
            <th>Playoffs</th>
            <th>Active</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {seasons.map((season) => (
            <tr key={season.season_id}>
              <td>{season.season_id}</td>
              <td>
                {editingId === season.season_id ? (
                  <input
                    type="text"
                    value={newSeasonName}
                    onChange={(e) => setNewSeasonName(e.target.value)}
                  />
                ) : (
                  <Link
                    to={{
                      pathname: `/ManageBasketball/${season.season_id}`,
                      state: { season_name: season.season_name }, // Pass season_name
                    }}
                  >
                    {season.season_name}
                  </Link>
                )}
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={season.is_playoffs}
                  onChange={() => togglePlayoffs(season.season_id)}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={season.is_active}
                  onChange={() =>
                    toggleSeasonActive(season.season_id, !season.is_active)
                  }
                />
              </td>
              <td>
                {editingId === season.season_id ? (
                  <>
                    <SaveButton onClick={handleSaveEdit}>Save</SaveButton>
                    <CancelButton onClick={() => setEditingId(null)}>
                      Cancel
                    </CancelButton>
                  </>
                ) : (
                  <EditButton
                    onClick={() => {
                      setEditingId(season.season_id);
                      setNewSeasonName(season.season_name);
                    }}
                  >
                    Edit
                  </EditButton>
                )}
              </td>
              <td>
                <DeleteButton onClick={() => deleteSeason(season.season_id)}>
                  Delete
                </DeleteButton>
              </td>
            </tr>
          ))}
          {addingNew && (
            <tr>
              <td>New</td>
              <td>
                <input
                  type="text"
                  value={newSeasonName}
                  onChange={(e) => setNewSeasonName(e.target.value)}
                  placeholder="Enter season name"
                />
              </td>
              <td>
                <button disabled>No</button>
              </td>
              <td>
                <button disabled>No</button>
              </td>
              <td>
                <SaveButton onClick={handleSaveNewSeason}>Save</SaveButton>
              </td>
              <td>
                <CancelButton
                  onClick={() => {
                    setAddingNew(false);
                    setNewSeasonName("");
                  }}
                >
                  Cancel
                </CancelButton>
              </td>
            </tr>
          )}
        </tbody>
      </TeamTable>
      

      {!addingNew && (
        <AddButton onClick={() => setAddingNew(true)}>Add Season</AddButton>
      )}
    </Container>
  );
}

export default ManageSeasons;
