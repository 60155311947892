import React, { useState } from "react";
import { useAuth } from "./useAuth"; // Import the custom auth hook
import { useNavigate } from "react-router-dom";
import {
  LoginContainer,
  LoginForm,
  FormGroup,
  ErrorMessage,
  SubmitButton,
  Heading,
} from "./Login.styled"; // Import the styled components

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth(); // Get the login function from the auth hook
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Call the login function from the hook
      await login(username, password);
      
      // Redirect to the protected route on successful login
      navigate("/ManageBasketball");
    } catch (err) {
      // Handle errors from the login function
      console.log("Login unsuccessful!");  
      setError(err.message || "Invalid username or password.");
    }
  };

  return (
    <LoginContainer>
      <LoginForm onSubmit={handleLogin}>
        <Heading>Login</Heading>
        <FormGroup>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </FormGroup>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <SubmitButton type="submit">Login</SubmitButton>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
