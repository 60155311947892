import { useState, useEffect, useCallback } from "react";

//const API_BASE_URL = "http://localhost:5000/api/basketball";

//const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/basketball`;
//const API_BASE_URL = `/api/basketball`;

const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/basketball" // Local testing
    : "/api/basketball"; // Production URL (relative path)



export const useScheduler = (seasonId) => {
  const [season, setSeason] = useState(null);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [error, setError] = useState("");

  const fetchSeasonDetails = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/seasons/get_seasons`);
      if (!response.ok) throw new Error("Failed to fetch season details.");
      const data = await response.json();
      const selectedSeason = data.find(
        (season) => season.season_id.toString() === seasonId
      );
      setSeason(selectedSeason);
    } catch (err) {
      console.error("Error fetching season details:", err);
      setError("Failed to fetch season details.");
    }
  }, [seasonId]);

  const fetchTeams = useCallback(async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/teams?season_id=${seasonId}`
      );
      if (!response.ok) throw new Error("Failed to fetch teams.");
      const data = await response.json();
      setTeams(data);
    } catch (err) {
      console.error("Error fetching teams:", err);
      setError("Failed to fetch teams.");
    }
  }, [seasonId]);

  const fetchGames = useCallback(async () => {
    console.log("API Base URL:", API_BASE_URL);
    try {
      // Use the correct route for fetching games
      const response = await fetch(`${API_BASE_URL}/schedules/${seasonId}`);
      if (!response.ok) throw new Error("Failed to fetch games.");
      const data = await response.json();
      setGames(data);
    } catch (err) {
      console.error("Error fetching games:", err);
      setError("Failed to fetch games.");
    }
  }, [seasonId]);

  const addGame = async (newGame) => {
    try {
      const formattedDate = new Date(newGame.game_date).toISOString();

      const response = await fetch(`${API_BASE_URL}/schedules`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...newGame,
          season_id: seasonId,
          game_date: formattedDate,
        }),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(errorDetails.error || "Failed to add game.");
      }

      const addedGame = await response.json();
      console.log("Added game:", addedGame);

      setGames((prevGames) => [...prevGames, addedGame]);
    } catch (err) {
      console.error("Error adding game:", err.message);
      setError(err.message || "Failed to add game.");
    }
  };

  const updateGame = async (gameId, updatedGame) => {
    try {
      const response = await fetch(`${API_BASE_URL}/schedules/${gameId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedGame),
      });
      if (!response.ok) throw new Error("Failed to update game.");
      setGames((prevGames) =>
        prevGames.map((game) =>
          game.game_id === gameId ? { ...game, ...updatedGame } : game
        )
      );
    } catch (err) {
      console.error("Error updating game:", err);
      setError("Failed to update game.");
    }
  };

  const deleteGame = async (gameId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/schedules/${seasonId}/${gameId}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) throw new Error("Failed to delete game.");
      setGames((prevGames) =>
        prevGames.filter((game) => game.game_id !== gameId)
      );
    } catch (err) {
      console.error("Error deleting game:", err);
      setError("Failed to delete game.");
    }
  };

  const bulkUpdateGames = async (bulkUpdates) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/schedules/${seasonId}/update_bulk_games`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ updates: bulkUpdates }),
        }
      );
  
      if (!response.ok) throw new Error("Failed to apply bulk updates.");
  
      // Optionally, handle the response data
      const result = await response.json();
      console.log(result.message);
  
      // Refresh the games list or update the state as needed
      fetchGames();
    } catch (err) {
      console.error("Error applying bulk updates:", err);
      // Handle error appropriately in the UI
    }
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchSeasonDetails(), fetchTeams(), fetchGames()]);
      } catch (err) {
        console.error("Error fetching schedule data:", err);
        setError("Failed to fetch schedule data.");
      }
    };

    if (seasonId) fetchData();
  }, [seasonId, fetchSeasonDetails, fetchTeams, fetchGames]);

  return {
    season,
    teams,
    games,
    error,
    fetchSeasonDetails,
    fetchTeams,
    fetchGames,
    addGame,
    updateGame,
    deleteGame,
    bulkUpdateGames,
  };
};
