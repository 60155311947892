import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import ThemeProvider from "./ColorContext";
import { MainContent, Background, backgroundImages } from "./App.styled";
import Burger from "./assets/components/BurgerDropDown/Burger";
import Menu from "./assets/components/Menu";
import Footer from "./assets/components/Footer";
import Home from "./assets/components/Home";
import FlagFootball from "./assets/components/FlagFootball/FlagFootball";
import Tournaments from "./assets/components/FlagFootball/Tournaments";
import Basketball from "./assets/components/Basketball";
import Kickball from "./assets/components/Kickball";
import League from "./assets/components/League";
//import PlayoffBracket from "./assets/components/League/PlayoffBracket";
import About from "./assets/components/About";
import YouthCamps from "./assets/components/YouthCamps";
import Team from "./assets/components/Team";
import ManageBasketball from "./assets/components/Basketball/Admin/ManageBasketball";
import ManageSeasons from "./assets/components/Basketball/Admin/ManageSeasons";
import ManageSchedule from "./assets/components/Basketball/Admin/ManageSchedule";
import ManageTeams from "./assets/components/Basketball/Admin/ManageTeams";
import AddDeleteGames from "./assets/components/Basketball/Admin/AddDeleteGames";
import Login from "./assets/components/Admin/Login"; // Add Login Component
import { useAuth } from "./assets/components/Admin/useAuth";
import ProtectedRoute from "./assets/components/Admin/ProtectedRoute";

const AppContent = () => {
  const [backgroundImage, setBackgroundImage] = useState(backgroundImages["/"]);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Use the useLocation hook to get the current location
  const {  logout } = useAuth();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen]);

  useEffect(() => {
    const newLocation = location.pathname;
    setBackgroundImage(backgroundImages[newLocation] || backgroundImages["/"]);
  }, [location]);

  return (
    <div className="App">
      <Burger isOpen={isOpen} setIsOpen={setIsOpen} />
      <Menu isOpen={isOpen} />
      <MainContent>
        <Background backgroundImage={backgroundImage} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/FlagFootball" element={<FlagFootball />} />
          <Route path="/Tournaments" element={<Tournaments />} />
          <Route path="/Basketball" element={<Basketball />} />
          <Route path="/Kickball" element={<Kickball />} />
          <Route path="/Basketball/Schedule/:seasonId" element={<League />} />
          {/*<Route path="/Basketball/Schedule" element={<PlayoffBracket />} />*/}
          <Route path="/About" element={<About />} />
          <Route path="/YouthCamps" element={<YouthCamps />} />
          <Route path="/team/:teamId" element={<Team />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/logout"
            element={<Navigate to="/login" />}
            onClick={logout}
          />

          {/* Protected Routes */}
          <Route
            path="/ManageBasketball"
            element={
              <ProtectedRoute>
                <ManageBasketball />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ManageBasketball/:seasonId"
            element={
              <ProtectedRoute>
                <ManageSchedule />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ManageBasketball/AddDeleteGames/:seasonId"
            element={
              <ProtectedRoute>
                <AddDeleteGames />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ManageBasketball/Seasons"
            element={
              <ProtectedRoute>
                <ManageSeasons />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ManageBasketball/Teams/:seasonId"
            element={
              <ProtectedRoute>
                <ManageTeams />
              </ProtectedRoute>
            }
          />
        </Routes>
      </MainContent>
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </Router>
  );
};

export default App;
