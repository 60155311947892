import { format } from "date-fns";
import { useParams, useNavigate } from "react-router-dom";

import React, { useState } from "react";
import {
  Container,
  Header,
  Table,
  TableContainer,
  CardContainer,
  Card,
  EditGamesButton,
  BackButton,
  BulkUpdateButton,
} from "./ManageBasketball.styled";
import { useScheduler } from "./hooks/useScheduler";

function ManageSchedule() {
  const { seasonId } = useParams();

  const { season, teams, games, error, bulkUpdateGames } =
    useScheduler(seasonId);
  console.log("Season games:", games);
  const [bulkUpdates, setBulkUpdates] = useState([]);
  const navigate = useNavigate();

  const handleBulkEditChange = (gameId, field, value) => {
    setBulkUpdates((prev) => {
      const updatedGame = prev.find((g) => g.game_id === gameId);
      if (updatedGame) {
        return prev.map((g) =>
          g.game_id === gameId ? { ...g, [field]: value } : g
        );
      } else {
        return [...prev, { game_id: gameId, [field]: value }];
      }
    });
  };

  const handleBulkSave = async () => {
    if (bulkUpdates.length === 0) {
      alert("No changes made to save.");
      return;
    }
    try {
      await bulkUpdateGames(bulkUpdates);
      alert("Scores updated successfully.");
      setBulkUpdates([]);
    } catch (err) {
      alert("Failed to update scores.");
    }
  };

  if (!season) return <p>Loading Season Data...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container>
      <Header>
        <h1>{season.season_name} - Manage Schedule</h1>
      </Header>

      <div style={{ marginBottom: "20px" }}>
        <BackButton onClick={() => navigate(-1)}>Back</BackButton>
      </div>
      <TableContainer>
        {/* Desktop Table Layout */}
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Team 1</th>
              <th>Score</th>
              <th>Team 2</th>
              <th>Score</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {games.length === 0 ? (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  No games available. Add a new game below.
                </td>
              </tr>
            ) : (
              games.map((game) => (
                <tr key={game.game_id}>
                  <td>
                    <input
                      type="date"
                      defaultValue={format(
                        new Date(game.game_date),
                        "yyyy-MM-dd"
                      )}
                      onChange={(e) =>
                        handleBulkEditChange(
                          game.game_id,
                          "game_date",
                          `${e.target.value}T${format(
                            new Date(game.game_date),
                            "HH:mm:ss"
                          )}`
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      defaultValue={format(new Date(game.game_date), "HH:mm")}
                      onChange={(e) => {
                        const datePart = format(
                          new Date(game.game_date),
                          "yyyy-MM-dd"
                        );
                        handleBulkEditChange(
                          game.game_id,
                          "game_date",
                          `${datePart}T${e.target.value}:00`
                        );
                      }}
                    />
                  </td>
                  <td>
                    {
                      teams.find((team) => team.team_id === game.team1_id)
                        ?.team_name
                    }
                  </td>
                  <td>
                    <input
                      type="number"
                      defaultValue={game.score1}
                      onBlur={(e) =>
                        handleBulkEditChange(
                          game.game_id,
                          "score1",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    {
                      teams.find((team) => team.team_id === game.team2_id)
                        ?.team_name
                    }
                  </td>
                  <td>
                    <input
                      type="number"
                      defaultValue={game.score2}
                      onBlur={(e) =>
                        handleBulkEditChange(
                          game.game_id,
                          "score2",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={game.note}
                      onBlur={(e) =>
                        handleBulkEditChange(
                          game.game_id,
                          "note",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        {/* Mobile Card Layout */}
        <CardContainer>
          {games.map((game) => (
            <Card key={game.game_id}>
              <div>
                <label>Date:</label>
                <input
                  type="date"
                  defaultValue={format(new Date(game.game_date), "yyyy-MM-dd")}
                  onChange={(e) =>
                    handleBulkEditChange(
                      game.game_id,
                      "game_date",
                      `${e.target.value}T${format(
                        new Date(game.game_date),
                        "HH:mm:ss"
                      )}`
                    )
                  }
                />
              </div>
              <div>
                <label>Time:</label>
                <input
                  type="time"
                  defaultValue={format(new Date(game.game_date), "HH:mm")}
                  onChange={(e) => {
                    const datePart = format(
                      new Date(game.game_date),
                      "yyyy-MM-dd"
                    );
                    handleBulkEditChange(
                      game.game_id,
                      "game_date",
                      `${datePart}T${e.target.value}:00`
                    );
                  }}
                />
              </div>
              <div>
                <label>Team 1:</label>
                <span>
                  {
                    teams.find((team) => team.team_id === game.team1_id)
                      ?.team_name
                  }
                </span>
              </div>
              <div>
                <label>Score:</label>
                <input
                  type="number"
                  defaultValue={game.score1}
                  onBlur={(e) =>
                    handleBulkEditChange(game.game_id, "score1", e.target.value)
                  }
                />
              </div>
              <div>
                <label>Team 2:</label>
                <span>
                  {
                    teams.find((team) => team.team_id === game.team2_id)
                      ?.team_name
                  }
                </span>
              </div>
              <div>
                <label>Score:</label>
                <input
                  type="number"
                  defaultValue={game.score2}
                  onBlur={(e) =>
                    handleBulkEditChange(game.game_id, "score2", e.target.value)
                  }
                />
              </div>
              <div>
                <label>Note:</label>
                <input
                  type="text"
                  defaultValue={game.note}
                  onBlur={(e) =>
                    handleBulkEditChange(game.game_id, "note", e.target.value)
                  }
                />
              </div>
            </Card>
          ))}
        </CardContainer>
      </TableContainer>
      <BulkUpdateButton onClick={handleBulkSave}>
        Save All Changes
      </BulkUpdateButton>

      <EditGamesButton
        onClick={() =>
          navigate(`/ManageBasketball/AddDeleteGames/${seasonId}`, {
            state: { season },
          })
        }
        style={{ marginLeft: "20px" }}
      >
        Add/Delete Games
      </EditGamesButton>
    </Container>
  );
}

export default ManageSchedule;
