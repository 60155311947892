import { useState, useCallback } from "react";

//const API_BASE_URL = "http://localhost:5000/api/basketball";

//const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/basketball`;
//const API_BASE_URL = `/api/basketball`;

const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/basketball" // Local testing
    : "/api/basketball"; // Production URL (relative path)

export const useTeams = (seasonId) => {
  const [teams, setTeams] = useState([]);
  const [error, setError] = useState(null);

  // Fetch teams from the API
  const fetchTeams = useCallback(async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/teams?season_id=${seasonId}`
      );
      if (!response.ok) throw new Error("Failed to fetch teams.");
      const data = await response.json();
      setTeams(data);
      setError(null); // Clear previous errors
    } catch (err) {
      console.error("Fetch Teams: Error fetching teams", err.message);
      alert("Failed to fetch teams. Please try again.");
    }
  }, [seasonId]);

  // Add a new team
  const addTeam = useCallback(
    async (teamName) => {
      console.log("season:", seasonId);
      if (!teamName.trim()) {
        alert("Team name cannot be empty.");
        return;
      }

      try {
        const response = await fetch(
          `${API_BASE_URL}/teams/${seasonId}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ team_name: teamName }), // Pass `team_name` in the body
          }
        );

        if (!response.ok) throw new Error("Failed to add team.");

        const addedTeam = await response.json();
        setTeams((prevTeams) => [...prevTeams, addedTeam]);
        setError(null); // Clear any previous errors
      } catch (err) {
        console.error("Add Team: Error adding team", err.message);
        alert("Failed to add team. Please try again.");
      }
    },
    [seasonId]
  );

  const editTeam = useCallback(
    async (teamId, teamName) => {
      if (!teamId || !teamName.trim()) {
        alert("Team ID and name are required.");
        return;
      }

      const previousTeams = [...teams];

      // Optimistic Update
      setTeams((prevTeams) =>
        prevTeams.map((team) =>
          team.team_id === teamId ? { ...team, team_name: teamName } : team
        )
      );

      try {
        const response = await fetch(
          `${API_BASE_URL}/teams/${teamId}/${seasonId}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ team_name: teamName }),
          }
        );

        if (!response.ok) throw new Error("Failed to update team.");

        const updatedTeam = await response.json();
        if (!updatedTeam || !updatedTeam.team || !updatedTeam.team.team_name) {
          console.error("API did not return a valid updated team.");
          throw new Error("Invalid server response.");
        }

        // Synchronize with backend response
        setTeams((prevTeams) =>
          prevTeams.map((team) =>
            team.team_id === teamId ? { ...team, ...updatedTeam.team } : team
          )
        );
        setError(null);
      } catch (err) {
        console.error("Edit Team: Error editing team", err.message);
        setTeams(previousTeams); // Rollback on failure
        alert("Failed to edit team. Changes rolled back.");
      }
    },
    [teams, seasonId]
  );

  // Delete a team
  const deleteTeam = useCallback(
    async (teamId) => {
      if (!teamId) {
        setError("Team ID is required.");
        return;
      }

      const previousTeams = [...teams];

      // Optimistic Update
      setTeams((prevTeams) =>
        prevTeams.filter((team) => team.team_id !== teamId)
      );

      try {
        const response = await fetch(
          `${API_BASE_URL}/teams/${teamId}/${seasonId}`,
          { method: "DELETE" }
        );

        if (!response.ok) throw new Error("Failed to delete team.");
        setError(null);
      } catch (err) {
        console.error("Delete Team: Error deleting team", err.message);
        setTeams(previousTeams); // Rollback
        alert("Failed to delete team. Changes rolled back.");
      }
    },
    [teams, seasonId]
  );

  return { teams, fetchTeams, addTeam, editTeam, deleteTeam, error };
};
