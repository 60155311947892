import styled from "styled-components";
import { singleColorMap } from "../../../../App.styled";

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

export const BulkUpdateButton = styled.button`
  background-color: #ff9800; /* Orange color for bulk updates */
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e68a00; /* Darker shade on hover */
  }
`;

export const EditGamesButton = styled.button`
  background-color: #34b3d1; /* Orange color for bulk updates */
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2a93af; /* Darker shade on hover */
  }
`;
export const FormContainer = styled.div`
  margin-bottom: 20px;

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap; /* Wrap elements on smaller screens */
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
`;

export const StyledSelect = styled.select`
  flex: 1 1 calc(50% - 10px); /* Adjust width for smaller screens */
  padding: 8px;
  font-size: 14px;

  @media (max-width: 768px) {
    flex: 1 1 100%; /* Full width on smaller screens */
  }
`;

export const StyledInput = styled.input`
  flex: 1 1 100%;
  padding: 8px;
  font-size: 14px;
`;

export const StyledFormButton = styled.button`
  flex: 1 1 100%;
  padding: 10px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #0056b3;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
export const BackButton = styled.a`
  background-color: #34b3d1;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #2a93af;
  }
`;

export const AddButton = styled.a`
  background-color: #34b3d1;
  color: #fff;
  padding: 10px;
  margin: 10px 15px 20px 5px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #2a93af;
  }
`;



export const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;


export const TeamTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: white;

  th,
  td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  background-color: #f4f4f4;
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    white-space: nowrap;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  /* Hide the table on small screens */
  @media (max-width: 768px) {
    display: none;
  }
`;

export const CardContainer = styled.div`
  display: none; /* Hide cards by default */

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between cards */
  }
`;

export const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  & > div {
    flex: 1 1 100%; /* Full width by default */

    @media (min-width: 480px) {
      flex: 1 1 calc(50% - 10px); /* Two columns on medium screens */
    }
  }

  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 80%;
    padding: 8px;
    font-size: 14px;
  }
`;


export const EditButton = styled.button`
  color: #34b3d1;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SaveButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export const CancelButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  color: red;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

export const StatusContainer = styled.div`
  margin-bottom: 30px;
  text-align: center;

  h3 {
    margin: 5px 0;
  }
`;

export const ButtonTable = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
    max-width: 90%; /* Adjust width for smaller screens */
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;

  @media (max-width: 768px) {
    margin: 5px 0; /* Reduce margin for smaller screens */
  }
`;

export const StyledButton = styled.a`
  display: inline-block;
  width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  text-align: center;
  background-color: ${singleColorMap.ohanaBlue};
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${singleColorMap.secondaryGrey};
  }

  @media (max-width: 768px) {
    padding: 12px 15px; /* Smaller padding for smaller screens */
    font-size: 14px; /* Reduce font size */
  }

  @media (max-width: 480px) {
    padding: 10px 12px;
    font-size: 12px;
  }
`;
