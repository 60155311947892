import { useEffect, useState, useCallback } from "react";

//const API_BASE_URL = "http://localhost:5000/api/basketball";
//const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/basketball`;
//const API_BASE_URL = `/api/basketball`;

const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/basketball" // Local testing
    : "/api/basketball"; // Production URL (relative path)
    
export const useSeasons = () => {
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch all seasons (or active seasons if `activeOnly` is true)
  const fetchSeasons = useCallback(async (activeOnly = false) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/seasons/get_seasons${activeOnly ? "?active=true" : ""}`
      );
      if (!response.ok) throw new Error("Failed to fetch seasons.");
      const data = await response.json();
      setSeasons(data);
    } catch (err) {
      console.error(err.message);
      setError("Failed to fetch seasons. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Add a new season
  const addSeason = useCallback(async (seasonName) => {
    try {
      const response = await fetch(`${API_BASE_URL}/seasons/create_season`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ season_name: seasonName }),
      });
      if (!response.ok) throw new Error("Failed to add new season.");
      const newSeason = await response.json();
      setSeasons((prevSeasons) => [...prevSeasons, newSeason]);
    } catch (err) {
      console.error(err.message);
      setError("Failed to add new season.");
    }
  }, []);

  // Update a season's name
  const updateSeason = useCallback(async (seasonId, seasonName) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/seasons/update_season/${seasonId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ season_name: seasonName }),
        }
      );
      if (!response.ok) throw new Error("Failed to update season.");
      const updatedSeason = await response.json();
      setSeasons((prevSeasons) =>
        prevSeasons.map((season) =>
          season.season_id === seasonId
            ? { ...season, season_name: updatedSeason.season_name }
            : season
        )
      );
    } catch (err) {
      console.error(err.message);
      setError("Failed to update season.");
    }
  }, []);

  // Delete a season
  const deleteSeason = useCallback(async (seasonId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/seasons/delete_season/${seasonId}`,
        { method: "DELETE" }
      );
      if (!response.ok) throw new Error("Failed to delete season.");
      setSeasons((prevSeasons) =>
        prevSeasons.filter((season) => season.season_id !== seasonId)
      );
    } catch (err) {
      console.error(err.message);
      setError("Failed to delete season.");
    }
  }, []);

  // Toggle season active status
  const toggleSeasonActive = useCallback(
    async (seasonId, isActive) => {
      console.log("Toggling active status:", { seasonId, isActive });
      try {
        const response = await fetch(
          `${API_BASE_URL}/seasons/set_season_active/${seasonId}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ is_active: isActive }),
          }
        );
        if (!response.ok)
          throw new Error("Failed to toggle season active status.");
        await fetchSeasons(); // Refresh the seasons list
      } catch (err) {
        console.error(err.message);
        setError("Failed to toggle season active status.");
      }
    },
    [fetchSeasons]
  );

  // Toggle playoffs
  const togglePlayoffs = useCallback(
    async (seasonId) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/seasons/toggle_playoffs/${seasonId}`,
          { method: "PUT" }
        );
        if (!response.ok) throw new Error("Failed to toggle playoffs.");
        await fetchSeasons(); // Refresh the seasons list
      } catch (err) {
        console.error(err.message);
        setError("Failed to toggle playoffs.");
      }
    },
    [fetchSeasons]
  );

  // Initial fetch on mount
  useEffect(() => {
    fetchSeasons();
  }, [fetchSeasons]);

  return {
    seasons,
    loading,
    error,
    fetchSeasons, // Fetch seasons (all or active)
    addSeason,
    updateSeason,
    deleteSeason,
    toggleSeasonActive,
    togglePlayoffs,
  };
};
