import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MainContent, Subtitle } from "../../../../App.styled";
import { useSeasons } from "./hooks/useSeasons";
import {
  StatusContainer,
  ButtonTable,
  Row,
  StyledButton,
} from "./ManageBasketball.styled";

const ManageBasketball = () => {
  const {
    seasons, // All seasons
    loading,
    error,
    fetchSeasons, // Fetch all seasons (with active filtering if needed)
  } = useSeasons();

  const [selectedSeason, setSelectedSeason] = useState(null); // Tracks the currently selected season
  const navigate = useNavigate();

  // Fetch active seasons on component mount
  useEffect(() => {
    fetchSeasons(true); // Fetch only active seasons
  }, [fetchSeasons]);

  // Update the selected season whenever the list of seasons changes
  useEffect(() => {
    if (seasons.length > 0) {
      setSelectedSeason(seasons[0]); // Default to the first active season
    }
  }, [seasons]);

  // Handle dropdown selection
  const handleSeasonChange = (event) => {
    const seasonId = parseInt(event.target.value, 10);
    const season = seasons.find((s) => s.season_id === seasonId);

 
    setSelectedSeason(season);
  };

  return (
    <MainContent>
      <StatusContainer>
        <Subtitle>Basketball League Manager</Subtitle>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p style={{ color: "red" }}>{error}</p>
        ) : (
          <div>
            <h3>Select a Season:</h3>
            <select
              value={selectedSeason?.season_id || ""}
              onChange={handleSeasonChange}
              style={{ padding: "8px", fontSize: "16px" }}
            >
              {seasons.map((season) => (
                <option key={season.season_id} value={season.season_id}>
                  {season.season_name}
                </option>
              ))}
            </select>

            {selectedSeason && (
              <>
                <h3>Selected Season: {selectedSeason.season_name}</h3>
                <h3>
                  Playoffs:{" "}
                  {selectedSeason.is_playoffs ? "Active" : "Not Active"}
                </h3>
              </>
            )}
          </div>
        )}
      </StatusContainer>
      
      <ButtonTable>
        <Row>
          <StyledButton
            onClick={() =>
              selectedSeason &&
              navigate(`/ManageBasketball/${selectedSeason.season_id}`)
            }
          >
            Manage Schedule
          </StyledButton>
        </Row>
        <Row>
          <StyledButton
            onClick={() =>
              selectedSeason &&
              navigate(`/ManageBasketball/`)
              // navigate(`/ManageBasketball/Playoffs/${selectedSeason.season_id}`)
            }
          >
            Manage Playoffs
          </StyledButton>
        </Row>
        <Row>
          <StyledButton
            onClick={() =>
              selectedSeason &&
              navigate(`/ManageBasketball/Teams/${selectedSeason.season_id}`)
            }
          >
            Manage Teams
          </StyledButton>
        </Row>
        <Row>
          <StyledButton onClick={() => navigate("/ManageBasketball/Seasons")}>
            Manage/Change Season
          </StyledButton>
        </Row>
      </ButtonTable>
      
    </MainContent>
  );
};

export default ManageBasketball;
