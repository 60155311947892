import React from "react";
import { useParams } from "react-router-dom";
import {
  ScheduleContainer,
  DateHeader,
  MatchupRow,
  GameTime,
  Versus,
  TeamScore,
  LeftScore,
  RightScore,
  TeamName,
} from "./Styles/Schedule.styled";
import { useScheduler } from "../Basketball/Admin/hooks/useScheduler";

// Utility to format the date title
const formatDateTitle = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

// Utility to format game time
const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

const Schedule = () => {
  const { seasonId } = useParams();
  const { games, teams, error } = useScheduler(seasonId);

  if (error) return <p>Error loading schedule: {error}</p>;
  if (!games || !teams) return <p>Loading schedule...</p>;
  if (games.length === 0) return <p>No games scheduled for this season.</p>;

  // Utility to get team name by team ID
  const getTeamName = (teamId) => {
    const team = teams.find((t) => t.team_id === teamId);
    return team ? team.team_name : "Unknown Team";
  };

  // Group games by date (adjusting for time zone differences)
  const groupedGames = games.reduce((acc, game) => {
    const gameDate = new Date(game.game_date); // Parse the date
    const localDate = new Date(
      gameDate.getFullYear(),
      gameDate.getMonth(),
      gameDate.getDate() + 1
    ); // Normalize to local midnight
    const dateKey = localDate.toISOString().split("T")[0]; // Use YYYY-MM-DD format

    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(game);
    return acc;
  }, {});
  // Convert grouped games into an array for rendering
  const days = Object.entries(groupedGames).map(([date, dayGames]) => ({
    date,
    dateTitle: formatDateTitle(date),
    games: dayGames,
  }));

  return (
    <>
      {days.map(({ dateTitle, games }) => (
        <ScheduleContainer key={dateTitle}>
          <DateHeader>{dateTitle}</DateHeader>
          {games.map((game) => (
            <MatchupRow key={game.game_id}>
              <TeamName>{getTeamName(game.team1_id)}</TeamName>
              <Versus>vs</Versus>
              <TeamName>{getTeamName(game.team2_id)}</TeamName>
              <GameTime>{formatTime(game.game_date)}</GameTime>
              <TeamScore>
                <LeftScore>{game.score1 ?? 0}</LeftScore>
                <RightScore>{game.score2 ?? 0}</RightScore>
              </TeamScore>
            </MatchupRow>
          ))}
        </ScheduleContainer>
      ))}
    </>
  );
};

export default Schedule;
