import React from 'react';
// import Standings from './Standings';
import { useParams } from "react-router-dom";
import Schedule from './Schedule';
import { SportContainer, TitleContainer, SportLandingTitle, SportName, Section } from '../../../App.styled';
import { useScheduler } from '../Basketball/Admin/hooks/useScheduler';

const League = () => {
  const { seasonId } = useParams();

  
  const { season } = useScheduler(seasonId);

  // Handle cases where `season` might not be available yet
  if (!season) {
    return (
      <Section>
        <SportContainer>
          <TitleContainer>
            <SportLandingTitle style={{ marginBottom: '2rem' }}>Loading Season...</SportLandingTitle>
          </TitleContainer>
        </SportContainer>
      </Section>
    );
  }

  return (
    <Section>
      <SportContainer>
        <TitleContainer>
          <SportLandingTitle style={{ marginBottom: '2rem' }}>
            {season.season_name} Basketball
          </SportLandingTitle>
        </TitleContainer>
        {/* <SportName>Standings</SportName> */}
        {/* <Standings /> */}
        <SportName style={{ marginTop: '2rem' }}>Schedule</SportName>
        <Schedule seasonId={seasonId} />
      </SportContainer>
    </Section>
  );
};

export default League;
