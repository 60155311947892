import { useState, useCallback } from "react";

const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/manager" // Local testing
    : "/api/manager"; // Production URL (relative path)

export const useAuth = () => {
  const [user, setUser] = useState(null); // Stores the user details
  const [error, setError] = useState(""); // Tracks errors
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("authToken") // Check if token exists
  );

  // Handle login
  const login = useCallback(async (username, password) => {
    try {
      const response = await fetch(`${API_BASE_URL}/logon`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(errorDetails.error || "Failed to log in.");
      }
    
      const data = await response.json();
      

      localStorage.setItem("authToken", data.token); // Store token in localStorage
      setUser({ username }); // Store user details (if needed)
      setIsAuthenticated(true);
      setError("");
    } catch (err) {
      console.error("Error logging in:", err.message);
      alert("Error logging in:", err.message);
      setError(err.message || "Failed to log in.");
    }
  }, []);

  // Handle logout
  const logout = useCallback(() => {
    localStorage.removeItem("authToken");
    setUser(null);
    setIsAuthenticated(false);
    setError("");
  }, []);

  // Validate token (optional)
  const validateToken = useCallback(async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setIsAuthenticated(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/validate-token`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) throw new Error("Invalid token.");

      const userData = await response.json();
      setUser(userData);
      setIsAuthenticated(true);
    } catch (err) {
      console.error("Error validating token:", err.message);
      logout();
    }
  }, [logout]);

  return {
    user,
    isAuthenticated,
    error,
    login,
    logout,
    validateToken,
  };
};
