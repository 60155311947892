import styled from 'styled-components';
import {Link} from 'react-router-dom';
import bellevue from "./assets/logo/Bellevue.avif"
import flagfootball from "./assets/logo/flagfootball.avif"
import football from "./assets/logo/football.webp"
import homepage from "./assets/logo/homepage.avif"
import basketball from "./assets/logo/basketball.jpg"
import trophy from "./assets/logo/trophy.webp"




//Color Key:

export const singleColorMap = {
  ohanaBlue: '#34b3d1', // Ohana blue
  darkCyanBlue: '#282c34', // Dark shade of cyan-blue
  greyestGrey: '#f6f6f6', // Greyest of greys
  darkCharcoal: '#333', // Dark charcoal
  ohanaYellow: '#ffd369', // Ohana yellow
  centralGrey: '#f8f8f8', // Centrally grey
  darkGreyBlack: '#2c2c2c', // Dark shade of gray-black
  secondaryGrey: '#333', // Secondary dark grey
};


export const colorMap = {
  light: {
    primary: '#34b3d1', // ohana blue
    secondary: '282c34', // dark shade of cyan-blue.
    background: '#f6f6f6', //greyest of greys
    text: '#333', //Dark charcoal 
    accent: '#ffd369', // ohana yellow
    secondaryBackground: '#f8f8f8' //pretty centrally grey
  },
  dark: {
    primary: '#34b3d1', 
    secondary: '#ffd369', 
    background: '#1e1e1e', 
    text: '#f6f6f6', 
    accent: '#2c2c2c', //dark shade of gray black
    secondaryBackground: '#333'
  }
};

export const backgroundImages = {
  '/': homepage,
  '/FlagFootball': football,
  '/SpringTournament': trophy,
  '/Basketball': basketball,
  '/About': bellevue,
  "/kickball": flagfootball,
};


const goldenRatio = 1.61803398875;

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: -1;
  background-size: cover; /* Use the zoom prop or default to 'cover' */
  background-position: center ;  
  background-repeat: no-repeat;
  filter: grayscale(100%);
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};

`;


export const MainContent = styled.div`
  font-family: 'Helvetica', sans-serif;
  width: 100%;
  margin: 0 auto; 
  flex-grow: 1;
  box-sizing: border-box;
  overflow-wrap: break-word;
  background-color: none;

 
`;

export const Highlight = styled.span`
color: #34b3d1;
font-weight: bold;
`;


export const Bold = styled.span`
color: #282c34;
font-weight: bold;
`


export const Section = styled.section`
  padding-left: 20px;
  padding-right: 10px;
  max-width: 1185px;
  margin: auto;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }
 
`;

export const Title = styled.h2`
color: ${colorMap.light.primary};
font-size: calc((${goldenRatio} * 20px)*${goldenRatio});
margin:20px;
 
`;

export const Subtitle = styled.h2`
order: 1; 
font-size: calc(${goldenRatio} * 20px);
align-items: start;
text-align: center;
color: #FFD369;
-webkit-text-stroke-width: 1px; 
-webkit-text-stroke-color: #1f1f1f; 
margin:15px;

@media (max-width: 430px) {
  -webkit-text-stroke-width: 0px; 
  color: #1f1f1f;
}
`;

export const Text = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: ${colorMap.light.text};
  overflow-wrap: break-word;
  text-align: left;
  
`;
export const FooterNoteText = styled(Text)`
font-size: 14px;
margin: 0 auto;
max-width: 768px;

text-align: center;
@media screen and (min-width: 768px) { 
  width: 768px;
}
`;

export const NoteText = styled(Text)`
background-color: #f8f8f8;  
padding: 10px;            
border-left: 4px solid #34b3d1; 
margin: 20px 0;           
font-style: italic;   
`;

export const LinkText = styled.a`
  font-size: 20px;
  line-height: 1.6;
  color: ${colorMap.light.accent}
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;

  &:hover {
    text-decoration: none; 
  }
`;



export const HighlightTextBold = styled.p`
position: relative;

color: #282c34;
text-align: center;
margin: 5px;
font-size: 20px;
font-weight: bold;
@media screen and (max-width: 600px) { 
 font-size: 16px;
}
`;

export const LinkButton= styled(Link)`
width: fit-content;
position: relative;
background-color: #FFD369;
padding: 10px;
margin: 10px auto;
border-radius: 20px 20px 20px 20px;
text-wrap: nowrap;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
text-align: center;
display: block;
color: #333;

text-decoration: none;
transition: color 0.2s; 

&::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0; 
  width: 0%; 
  background-color: #34b3d1;
  transition: width 0.3s ease; 
  border-radius: 20px 20px 20px 20px;
  border-color: #1f1f1f;
border-radius: 10px;

}

&:hover {
  color: ${colorMap.light.text};
  z-index: 100;

  &::before {
    width: 100%;
      background-color: ${colorMap.light.accent};
    z-index: -1;
  }

  
}
`;


//////////////////////////////SPORTS LANDING PAGE:




export const SportContainer = styled.div`
  margin: 5rem 0;
  display: flex;
  padding: 36px 86px;
  flex-direction: column;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.37);
  backdrop-filter: blur(22px);
  z-index: -1;
  @media(max-width: 1248px) {
    margin: 4rem auto;
  padding: 32px 80px;
  }
  @media(max-width: 912px) {
    margin: 3.5rem auto;
      padding: 28px 72px;

  }
  @media screen and (max-width: 768px) { 
  padding: 24px 5px;
}
  
`

export const InnerContentContainer = styled.div`
display: flex;
min-height: 678px;
width: 100%;

flex-direction: column;
justify-content: space-evenly;
align-items: center;

`

export const TitleContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
`

export const SportLandingTitle = styled.h1`

  color: ${colorMap.light.accent};
  margin: 0;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: ${colorMap.light.text};
  font-size: 42px;
  font-weight: 800;
  letter-spacing: .1rem;
  line-height: normal;
   @media screen and (max-width: 768px) { 
    font-size: 28px;
  font-weight: 700;
  letter-spacing: normal;
  -webkit-text-stroke-width: 0px;

}
`

export const SportName = styled.h1`
color: ${colorMap.light.primary};
  margin: 0;

text-align: center;
-webkit-text-stroke-width: 2px;
-webkit-text-stroke-color: ${colorMap.light.text};
font-size: 42px;
font-style: normal;
letter-spacing: .1rem;

font-weight: 800;
line-height: normal;
   @media screen and (max-width: 768px) { 
    font-size: 32px;
  font-weight: 700;
  letter-spacing: normal;
  -webkit-text-stroke-width: 0px;

}
`

export const RegisterButtonContainer = styled.div`
display: flex;
width: 609px;
height: 62px;
justify-content: space-between;
align-items: center;
 @media(max-width: 912px) {
    width: 500px;
    height: 62px;
    margin: 1.5em 0;
  }
 @media screen and (max-width: 768px) { 
width: 400px;
justify-content: space-evenly;

}
`




export const RegisterButton = styled(LinkButton)`

display: flex;
align-items: center;
justify-content: center;

flex-shrink: 0;

padding: 13px 27px;
gap: 10px;
width: 200px;

font-weight: bold;
border-radius: 10px;
background: #34B3D1;
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
@media(max-width: 912px) {
    padding: 5px;
  max-width: 150px;
  height: 32px;
  font-size: .8em;
  }
@media screen and (max-width: 768px) { 
  padding: 5px;
  max-width: 150px;
  height: 32px;
  font-size: .8em;
}
  &:hover{
    color: ${colorMap.light.accent}
     z-index: -1;
     color: ${colorMap.light.background}
  }
`


export const AllTextContainer = styled.div`
 display: flex;
height: auto;

flex-direction: column;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
`
export const MainTextContainer = styled.div`
  position: relative;
  display: flex;
  padding: 0 40px;
  justify-content: space-between;
  align-items: center;
  border-right: thick double gold;
  border-left: thick double gold;

   @media screen and (max-width: 768px) { 
  

}

  @media screen and (max-width: 768px) { 
  padding: 0 30px;
  padding: 0px;

}
`


export const MainText = styled.p`
width: 742px;
flex-shrink: 0;
color: ${colorMap.light.text};
text-align: center;
/* text - nokora */
font-family: Nokora;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
  @media(max-width: 1248px) {
    width: 572px;
  }
   @media(max-width: 912px) {
    margin-top: 1em;
  }
     @media screen and (max-width: 768px) { 
      width: 400px;
       padding: 0 12px;
}
       @media screen and (max-width: 612px) { 
       width: 350px;
       padding: 0 10px;
}

`

export const SpecialText = styled.p`
color: ${colorMap.light.text};
display: inline;
font-family: Nokora;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
`


export const SubtextContainer = styled.div`
display: flex;
width: 763px;
height: 84px;
margin-top: 5vh;
justify-content: space-between;
align-items: center;
flex-shrink: 0;

  @media(max-width: 1248px) {
  
  }
  @media(max-width: 912px) {
    justify-content: space-evenly;

  }
  @media(max-width: 912px) {
    flex-direction: column;
    height: auto;
    padding-bottom: 1rem;
  }
  
  @media screen and (max-width: 768px) { 
   width: 500px;
   margin-top: 2vh;


}
    @media screen and (max-width: 612px) { 
          width: 400px;

}
`


export const Subtext = styled.p`
width: 350px;
height: 84px;
flex-shrink: 0;
font-family: Nokora;
font-size: 16px;
font-style: italic;
font-weight: 400;
line-height: normal;
      text-align: justify;

  @media(max-width: 1248px) {
  width: 325px;

  }
  @media(max-width: 912px) {
    width: 275px;
    font-size: 14px;
  }
`

