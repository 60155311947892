import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useScheduler } from "./hooks/useScheduler";
import {
  Container,
  Header,
  Table,
  TableContainer,
  BackButton,

  DeleteButton,
  FormContainer,
  StyledForm,
  StyledSelect,
  StyledInput,
  StyledButton
} from "./ManageBasketball.styled";

const AddDeleteGames = () => {
  const location = useLocation();
  const season = location.state?.season || {};
  const { season_id: seasonId, season_name } = season;

  const { addGame, deleteGame, games, teams, fetchGames } = useScheduler(seasonId);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getDefaultDateTime = () => {
    const now = new Date();
    const minutes = Math.ceil(now.getMinutes() / 15) * 15;
    now.setMinutes(minutes);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return {
      date: now.toISOString().slice(0, 10),
      time: now.toISOString().slice(11, 16),
    };
  };

  const [newGame, setNewGame] = useState({
    season_id: seasonId || "",
    team1_id: "",
    team2_id: "",
    game_date: getDefaultDateTime().date,
    game_time: getDefaultDateTime().time,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const loadGames = async () => {
      setLoading(true);
      try {
        await fetchGames();
      } catch (err) {
        console.error("Failed to fetch games:", err);
        setError("Failed to load games. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    loadGames();
  }, [fetchGames]);

  const handleAddGame = async () => {
    if (!newGame.team1_id || !newGame.team2_id || !newGame.game_date || !newGame.game_time) {
      alert("All fields are required.");
      return;
    }
    if (newGame.team1_id === newGame.team2_id) {
      alert("Team 1 and Team 2 cannot be the same.");
      return;
    }
    try {
      setLoading(true);
      const combinedDateTime = `${newGame.game_date}T${newGame.game_time}:00`;
      await addGame({ ...newGame, game_date: combinedDateTime });
      setNewGame({
        season_id: seasonId,
        team1_id: "",
        team2_id: "",
        game_date: getDefaultDateTime().date,
        game_time: getDefaultDateTime().time,
      });
      await fetchGames();
    } catch (err) {
      alert("Failed to add game.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteGame = async (gameId) => {
    try {
      setLoading(true);
      await deleteGame(gameId);
      await fetchGames();
    } catch (err) {
      alert("Failed to delete game.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  return (
    <Container>
      <Header>
        <h1>Manage Basketball Games - {season_name || "Unknown"} Season</h1>
      </Header>

      <div style={{ marginBottom: "20px" }}>
        <BackButton onClick={() => navigate(-1)}>Back</BackButton>
      </div>
      
    
      <FormContainer>
    <h2>Add a New Game</h2>
    {teams?.length === 0 ? (
      <p>No teams available for this season. Add teams first.</p>
    ) : (
      <StyledForm>
        <StyledSelect
          value={newGame.team1_id}
          onChange={(e) =>
            setNewGame({ ...newGame, team1_id: e.target.value })
          }
        >
          <option value="">Select Team 1</option>
          {teams?.map((team) => (
            <option key={team.team_id} value={team.team_id}>
              {team.team_name}
            </option>
          ))}
        </StyledSelect>

        <StyledSelect
          value={newGame.team2_id}
          onChange={(e) =>
            setNewGame({ ...newGame, team2_id: e.target.value })
          }
        >
          <option value="">Select Team 2</option>
          {teams?.map((team) => (
            <option key={team.team_id} value={team.team_id}>
              {team.team_name}
            </option>
          ))}
        </StyledSelect>

        <StyledInput
          type="date"
          value={newGame.game_date}
          onChange={(e) =>
            setNewGame({ ...newGame, game_date: e.target.value })
          }
        />

        <StyledInput
          type="time"
          value={newGame.game_time}
          onChange={(e) =>
            setNewGame({ ...newGame, game_time: e.target.value })
          }
        />

        <StyledButton type="button" onClick={handleAddGame}>
          Add Game
        </StyledButton>
      </StyledForm>
    )}
  </FormContainer>
    

    <TableContainer>
        <h2>Existing Games</h2>
        <Table>
          <thead>
            <tr>
              <th>Game ID</th>
              <th>Team 1</th>
              <th>Team 2</th>
              <th>Date</th>
              <th>Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {games?.length > 0 ? (
              games.map((game) => (
                <tr key={game.game_id}>
                  <td>{game.game_id}</td>
                  <td>
                    {teams.find((t) => t.team_id === game.team1_id)
                      ?.team_name || game.team1_id}
                  </td>
                  <td>
                    {teams.find((t) => t.team_id === game.team2_id)
                      ?.team_name || game.team2_id}
                  </td>
                  <td>{format(new Date(game.game_date), "MMMM d, yyyy")}</td>
                  <td>{format(new Date(game.game_date), "h:mm a")}</td>
                  <td>
                    <DeleteButton
                      onClick={() => handleDeleteGame(game.game_id)}
                    >
                      Delete
                    </DeleteButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  No games available. Add a new game above.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AddDeleteGames;
