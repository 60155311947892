import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TeamTable,

  SaveButton,
  CancelButton,
  EditButton,
  DeleteButton,
  BackButton,
} from "./ManageBasketball.styled";
import { useTeams } from "./hooks/useTeams";
import { useSeasons } from "./hooks/useSeasons";

function ManageTeams() {
  const navigate = useNavigate();
  const [newTeamName, setNewTeamName] = useState("");
  const [editingTeamId, setEditingTeamId] = useState(null);
  const [editingTeamName, setEditingTeamName] = useState("");
  const { seasonId } = useParams();
  const {
    seasons,
    loading: seasonsLoading,
    error: seasonsError,
  } = useSeasons();

  console.log("Season ID:", seasonId); // Debugging
  const seasonName = seasons.find(
    (season) => season.season_id.toString() === seasonId
  )?.season_name;
  const { teams, fetchTeams, addTeam, editTeam, deleteTeam, error } =
    useTeams(seasonId);

  useEffect(() => {
    if (seasonId) {
      fetchTeams();
    }
  }, [fetchTeams, seasonId]);

  if (seasonsLoading) return <p>Loading seasons...</p>;
  if (seasonsError) return <p>Error loading seasons: {seasonsError}</p>;
  if (!seasonId) {
    return <p>Error: Season ID is required.</p>;
  }

  if (error) {
    return (
      <div style={{ padding: "20px" }}>
        <h2>Error</h2>
        <p>{error}</p>
        <BackButton onClick={() => navigate(-1)}>Back</BackButton>
      </div>
    );
  }

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <h1>Teams for {seasonName} Season</h1>
      <div style={{ marginBottom: "20px" }}>
        <BackButton onClick={() => navigate(-1)}>Back</BackButton>
      </div>
      
      <TeamTable>
        <thead>
          <tr>
            <th>Team ID</th>
            <th>Team Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.team_id}>
              <td>{team.team_id}</td>
              <td>
                {editingTeamId === team.team_id ? (
                  <input
                    type="text"
                    value={editingTeamName}
                    onChange={(e) => setEditingTeamName(e.target.value)}
                  />
                ) : (
                  team.team_name
                )}
              </td>
              <td>
                {editingTeamId === team.team_id ? (
                  <>
                    <SaveButton
                      onClick={() => {
                        editTeam(team.team_id, editingTeamName);
                        setEditingTeamId(null);
                        setEditingTeamName("");
                      }}
                    >
                      Save
                    </SaveButton>
                    <CancelButton
                      onClick={() => {
                        setEditingTeamId(null);
                        setEditingTeamName("");
                      }}
                    >
                      Cancel
                    </CancelButton>
                  </>
                ) : (
                  <>
                    <EditButton
                      onClick={() => {
                        setEditingTeamId(team.team_id);
                        setEditingTeamName(team.team_name);
                      }}
                    >
                      Edit
                    </EditButton>
                    <DeleteButton onClick={() => deleteTeam(team.team_id)}>
                      Delete
                    </DeleteButton>
                  </>
                )}
              </td>
            </tr>
          ))}
          <tr>
            <td>New</td>
            <td>
              <input
                type="text"
                value={newTeamName}
                onChange={(e) => setNewTeamName(e.target.value)}
                placeholder="Enter team name"
              />
            </td>
            <td>
              <SaveButton
                onClick={() => {
                  addTeam(newTeamName, seasonId);
                  setNewTeamName("");
                }}
              >
                Add
              </SaveButton>
            </td>
          </tr>
        </tbody>
      </TeamTable>

    
    </div>
  );
}

export default ManageTeams;
